<template>
  <div
    class="send-item"
    :id="'sendItem' + index"
    @click="goDetail"
    :style="['background-color:' + backgroundColor]"
  >
    <div
      v-if="isSupportEdit"
      class="icon-box-hotspot edit-icon"
      @click.stop="onEdit"
    >
      <div class="icon-container">
        <div class="cut-panel" :style="['background-color:' + cutColor]" />
        <img
          class="icon"
          :src="require('../../assets/img/suggestion/edit-icon.svg')"
        />
      </div>
    </div>
    <div class="icon-box-hotspot share-icon" @click.stop="onSend">
      <div class="icon-container">
        <div class="cut-panel" :style="['background-color:' + cutColor]" />
        <img
          class="icon"
          :src="require('../../assets/img/suggestion/share-icon.svg')"
        />
      </div>
    </div>
    <div v-if="pageType == 'task'" class="task-info task-time-box">
      <p class="task-title">{{ sendItem.name }}</p>
      <van-count-down
        v-if="sendItem.status == 0 && endTime > 0"
        @finish="sendItem.status = 2"
        :time="endTime"
      >
        <template #default="timeData">
          <span class="task-info-item">距离任务结束剩余&nbsp;&nbsp;</span>
          <span v-if="timeData.days">
            <span class="txt-blue weight500">{{ timeData.days }}</span>
            <span class="task-info-item">天&nbsp;</span>
          </span>
          <span v-if="timeData.hours">
            <span class="txt-blue weight500">{{ timeData.hours }}</span>
            <span class="task-info-item">时&nbsp;</span>
          </span>
          <span v-if="timeData.minutes">
            <span class="txt-blue weight500">{{ timeData.minutes }}</span>
            <span class="task-info-item">分&nbsp;</span>
          </span>
          <span v-if="timeData.seconds">
            <span class="txt-blue weight500">{{ timeData.seconds }}</span>
            <span class="task-info-item">秒</span>
          </span>
        </template>
      </van-count-down>
      <div v-else class="task-info-item">结束时间：{{ sendItem.end_time }}</div>
    </div>
    <div
      :class="['send-item-content', pageType == 'speech' ? '' : 'txt-line-3']"
      v-html="sendItem.content"
    ></div>
    <div
      v-if="mediaShowList.length"
      :class="['media-box', sendItem.content ? 'mt12' : '']"
    >
      <div
        class="media-item"
        v-for="(mediaItem, index) in mediaShowList"
        :key="index"
      >
        <!-- <img
          v-if="mediaItem.type !== 'VIDEO'"
          fit="fill"
          style="width: 48px; height: 48px"
          :src="
            showImgCover(
              mediaItem.file_url,
              mediaItem.type,
              mediaItem.file_suffix,
              mediaItem
            )
          "
        />-->
        <van-image
          v-if="mediaItem.type !== 'VIDEO'"
          width="48px"
          height="48px"
          fit="fill"
          :src="
            showImgCover(
              mediaItem.file_url,
              mediaItem.type,
              mediaItem.file_suffix
            )
          "
        />
        <video
          class="cover-img"
          v-else
          fit="fill"
          :src="mediaItem.file_url"
        ></video>
        <img
          v-if="['VIDEO', 'MINIPROGRAM', 'LINK'].includes(mediaItem.type)"
          class="play-icon"
          :src="require('@/assets/img/quickSend/' + mediaItem.type + '.svg')"
          alt="paly"
        />
      </div>
      <div v-if="maxMedia" class="over-box">
        ...共
        <span class="txt-blue">{{ mediaItemList.length }}</span>
        个
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, CountDown } from 'vant'
import {
  // getCurExternalContact,
  sendChatMessageAllType,
  openExistedChatWithMsg
} from '../../utils/nwechat.js'
import {
  backTaskStatus,
  getKnowledgeDetail,
  sendMonitor
} from '../../service/quickSend.service.js'
import {
  contentToRed,
  heightLight,
  formatParamsDistribution
} from '../../utils/index'
import { backPersonalSend, setBackSend } from '../../service/quickSend.service'
import { formatTaskItem } from '@/utils/sendMessage'

export default {
  components: {
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown
  },
  name: 'sendListItem',
  props: {
    sendItem: {
      // 主体数据
      type: Object,
      default() {
        return {}
      }
    },
    mediaList: {
      // 媒体数据
      type: Array,
      default() {
        return []
      }
    },
    limitNum: {
      // 限制媒体数量
      type: Number,
      default() {
        return 5
      }
    },
    pageType: {
      // 父页面类型
      type: String,
      default() {
        return 'knowledge' // task, knowledge, personal, recommand_review
      }
    },
    customer_userid: {
      // 成员id
      type: String,
      default() {
        return ''
      }
    },
    chat_id: {
      // 群id
      type: String,
      default() {
        return ''
      }
    },
    member_userid: {
      // 外部id(客户id)
      type: String,
      default() {
        return ''
      }
    },
    labelSpeechItem: {
      type: Object,
      default() {
        return {}
      }
    },
    // 标签营销-标签数组
    tag_id_arr: {
      type: Array,
      default() {
        return []
      }
    },
    index: {
      // 组件处于第几个
      type: Number,
      default() {
        return -1
      }
    },
    keyArr: {
      type: Array,
      default() {
        return []
      }
    },
    backgroundColor: {
      // 成员id
      type: String,
      default() {
        return '#fff'
      }
    },
    cutColor: {
      // 成员id
      type: String,
      default() {
        return ''
      }
    },
    isNormal: {
      // 判断是从工作台进入还是工具栏，工作台为true
      type: Boolean
    },
    isSupportEdit: {
      // 是否支持编辑
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      maxMedia: false,
      taskStatus: false,
      mediaShowList: [],
      mediaItemList: [],
      share_data_id: ''
    }
  },
  created() {
    // console.log('数据-----------', this.sendItem)
    if (this.pageType == 'task') {
      this.endTime = this.sendItem.end_time_strtotime * 1000 - new Date()
      // console.log(this.endTime)
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  // watch: {
  //   mediaList: {
  //     deep: true, //深度监听设置为 true, 搜索时飘红
  //     handler: function (newV, oldV) {
  //       console.log('watch中：', newV)
  //       if (newV.length) {
  //         if (this.pageType === 'task') {
  //           let arr = JSON.parse(JSON.stringify(this.mediaList))
  //           this.mediaItemList = formatTaskItem(arr)
  //         }
  //       }
  //     }
  //   }
  // },

  methods: {
    async init() {
      let arr = JSON.parse(JSON.stringify(this.mediaList))
      if (this.pageType === 'task') {
        let arr = JSON.parse(JSON.stringify(this.mediaList))
        this.mediaItemList = formatTaskItem(arr)
      } else {
        this.mediaItemList = arr
      } // 文字飘红, 飘红之前先把文字内容摘出来
      this.sendItem.testContent = this.sendItem.content
      this.keyArr.forEach((item) => {
        let str = heightLight(item, this.sendItem.content)
        this.sendItem.content = str
      })
      this.limitMadie()
      // console.log('媒体列表', this.mediaItemList)
    },

    // 限制媒体数量
    limitMadie() {
      // 深拷贝
      this.mediaShowList = JSON.parse(JSON.stringify(this.mediaList))

      if (this.mediaShowList.length > this.limitNum) {
        this.maxMedia = true
        this.mediaShowList.splice(
          this.limitNum,
          this.mediaShowList.length - this.limitNum
        )
      } else {
        this.maxMedia = false
      }
      let arr = JSON.parse(JSON.stringify(this.mediaShowList))
      this.mediaShowList = formatTaskItem(arr)
    },

    // 前往详情页
    goDetail() {
      let id = ''
      let path = ''
      let task_id
      if (this.pageType == 'task') {
        // 任务
        if (this.isSupportEdit) {
          return this.onEdit()
        }
        id = this.sendItem.id
        task_id = this.sendItem.task_id
        path = '/marketing-task-detail'
      } else if (this.pageType == 'knowledge') {
        // 知识库
        id = this.sendItem.sale_script_id
        path = '/knowledge-detail'
      } else if (this.pageType == 'speech') {
        // 我的话术-话术列表不做跳转
        return
      } else if (this.pageType == 'label') {
        // 标签营销
        path = '/labelmarketing-detail'
        id = this.sendItem.sale_script_id
        // sessionStorage.setItem('labelMarketingDetail', this.labelSpeechItem)
      }
      this.$router.push({
        path: path,
        query: {
          id: id,
          chat_id: this.chat_id,
          task_id,
          customer_userid: this.customer_userid
        }
      })
    },
    // 点击编辑
    async onEdit() {
      this.$emit('onEdit', this.sendItem)
    },

    // 发送前的埋点回调
    async beforeSend() {
      let typeObj = {
        knowledge: 'knowledge',
        task: 'task',
        label: 'tag_marketing'
      }
      // 个人话术没有取值,不需要分享参数
      if (!typeObj[this.pageType]) {
        return
      }
      let { data, code } = await sendMonitor({
        payable_id: this.sendItem.task_id || this.sendItem.sale_script_id,
        type: typeObj[this.pageType],
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    async onSend() {
      if (this.isNormal) {
        await openExistedChatWithMsg({
          chatId: this.chat_id
        })
        return
      }

      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        let mediaList = []
        // 根据页面类型
        if (['knowledge', 'label'].includes(this.pageType)) {
          let { data } = await getKnowledgeDetail({
            sale_script_id: this.sendItem.sale_script_id
          })
          mediaList = data.content_arr || []
        } else if (['task', 'speech'].includes(this.pageType)) {
          console.log('疑点打印111', this.mediaItemList)
          let arr = JSON.parse(JSON.stringify(this.mediaItemList))
          if (this.sendItem.testContent) {
            arr.unshift({
              type: 'TEXT',
              content: this.sendItem.testContent
            })
          }
          mediaList = arr
          console.log('疑点打印222', mediaList)
        } else {
          mediaList = this.mediaItemList
        }
        for (let i = 0; i < mediaList.length; i++) {
          let type = await this.formatType(mediaList[i].type)
          let params = await this.formatSendParams(
            type,
            mediaList[i],
            this.share_data_id
          )
          console.log('发送前参数', type, params)
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          // console.log('发送状态：-----------：', sendStatus)
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }
      } catch (e) {
        console.log(e)
      }

      console.log('发送状态11----------', this.taskStatus)
      if (this.taskStatus) {
        // 数据埋点，xx发送什么话术埋点
        let userStorage = JSON.parse(localStorage.getItem('vuex'))
        let person = userStorage.user.info.qw_name
        let nameObj = {
          task: '任务',
          ai_replay: '推荐回复',
          label: '标签营销',
          knowledge: '知识库',
          speech: '个人话术'
        }
        let scriptType = nameObj[this.pageType]
        window._paq.push([
          'trackEvent',
          '知识库行为',
          '发送话术',
          `${person}发送了${this.sendItem.sale_script_id}，类型是${scriptType}`,
          1
        ])
        console.log('md统计1')
      }

      // 任务发送后回传状态
      if (this.taskStatus && this.pageType == 'task') {
        // 回传改变状态-任务
        await backTaskStatus({
          send_record_son_id: this.sendItem.task_send_record_son_id,
          member_userid: this.member_userid,
          external_userid: this.customer_userid || this.chat_id,
          send_status: this.taskStatus ? 'success' : 'fail'
        }).then((res) => {
          if (res.code == 200) {
            console.log('1111sendSuccess', this.index)
            this.sendItem.status = 1
            this.$emit('sendSuccess', this.index)
          }
        })
      }
      if (this.taskStatus && this.pageType == 'speech') {
        // 回传改变状态-话术
        await backPersonalSend({
          sale_script_id: this.sendItem.sale_script_id
        }).then((res) => {
          if (res.code == 200) {
            // this.$emit('sendSuccess', this.index)
          }
        })
      }

      // 发送之后进行回调
      this.taskStatus && this.onSended()
    },

    // 发送之后进行回调
    async onSended() {
      if (this.pageType == 'task') {
        // window.location.href = 'about:blank'
        // window.close()
        wx.closeWindow()
        return
      }
      let obg = {
        knowledge: 'customer_receive_knowledge',
        speech: 'customer_receive_words',
        label: 'customer_receive_tag'
      }
      let type = obg[this.pageType]
      let field = 'external_contact'
      if (this.chat_id) {
        let groupObj = {
          knowledge: 'customer_touch_knowledge_group',
          speech: 'customer_touch_words_group',
          label: 'customer_touch_tag_group'
        }
        type = groupObj[this.pageType]
        field = 'chat_id'
      }

      await setBackSend({
        sale_script_id: this.sendItem.sale_script_id,
        event_key: type,
        field: field,
        field_value: this.customer_userid || this.chat_id,
        tag_idx: this.tag_id_arr || []
      })
      // 发送完成后关闭当前窗口
      // window.location.href = 'about:blank'
      // window.close()
      wx.closeWindow()
    },

    // 判断wechat文件类型
    formatType(medisType) {
      let type = ''
      switch (medisType) {
        case 'TEXT':
          type = 'text'
          break
        case 'IMAGE':
          type = 'image'
          break
        case 'LINK':
          type = 'news'
          break
        case 'VIDEO':
          type = 'video'
          break
        case 'MINIPROGRAM':
          type = 'miniprogram'
          break
        case 'FILE':
          type = 'news'
          break
        default:
          type = 'text'
          break
      }
      // console.log(1, type)
      return Promise.resolve(type)
    },

    // 分享(发送)参数配置
    async formatSendParams(type, item, share_data_id) {
      // console.log('参数', type, item)
      // 缺少http头部，补充
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl = item.file_url
            ? item.file_url
            : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.qw_link_url, //H5消息页面url 必填
            title: item.qw_link_title, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = item.qw_miniprogram_page
        const pathIndex = path.indexOf('?')
        if (pathIndex === -1) {
          if (path.indexOf('.html') === -1) {
            path = `${path}.html`
          }
          path = `${path}?task_id=${this.sendItem.task_id || ''}`
          // 商品附件
          if (item.goods_id) path = `${path}&goods_id=${item.goods_id}`
          // 优惠券附件
          if (item.coupon_id) path = `${path}&coupon_id=${item.coupon_id}`
        } else {
          if (path.indexOf('.html') === -1) {
            let pathArray = path.split('')
            const index = pathArray.indexOf('?')
            pathArray.splice(index, 0, '.html')
            path = pathArray.join('')
          }
          path = `${path}&task_id=${this.sendItem.task_id || ''}`

          // let temp_obj =
          // 商品附件
          if (item.goods_id) path = `${path}&goods_id=${item.goods_id}`
          // 优惠券附件
          if (item.coupon_id) path = `${path}&coupon_id=${item.coupon_id}`
        }

        // 处理加上分配需要参数
        path = await formatParamsDistribution(
          path,
          this.customer_userid,
          this.chat_id,
          this.pageType
        )
        // 如果存在埋点id加入埋点id
        if (share_data_id) path = `${path}&share_data_id=${share_data_id}`

        params[type] = {
          appid: item.qw_miniprogram_appid, //小程序的appid
          title: item.qw_miniprogram_title, //小程序消息的title
          imgUrl: item.file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      console.log(2, params)
      return Promise.resolve(params)
    },

    // 处理小程序路径加上分配所需参数
    // formatParamsDistribution(path) {
    //   let obj = {
    //     member_id: localStorage.getItem('member_id') || '',
    //     customer_id: this.customer_userid || '',
    //     chart_id: this.chat_id || '',
    //     source: this.pageType
    //   }
    //   let tempPath = path
    //   for (let key in obj) {
    //     if (obj[key]) {
    //       tempPath = `${tempPath}&${key}=` + obj[key]
    //     }
    //   }
    //   return Promise.resolve(tempPath)
    // },

    // 发送时获取图片封面
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    },

    //封面图片过滤
    showImgCover(url, type, file_suffix, item) {
      // console.log('附件封面', url, type, file_suffix, item)
      if (!url && type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      } else if (!url && type !== 'LINK') {
        return ''
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/word.svg')
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/pdf.svg')
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/excel.svg')
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/ppt.svg')
      }
      let baseUrl = url.indexOf('http') === -1 ? 'https://' : ''
      // console.log('附件封面', url)
      return baseUrl + url
    }
  }
}
</script>
<style lang="less" scoped>
.send-item {
  padding: 14px 12px;
  border: 1px solid #1773fa;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;

  .icon-box-hotspot {
    width: 32px;
    height: 32px;
    position: absolute;
    top: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-container {
    position: relative;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border: 1px solid #1773fa;
    border-radius: 13px;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 12px;
      z-index: 23;
      cursor: pointer;
    }
    .cut-panel {
      position: absolute;
      top: -4px;
      right: -5px;
      width: 44px;
      height: 15px;
      z-index: 22;
    }
  }
  .share-icon {
    right: 14px;
  }
  .edit-icon {
    right: 67px;
  }

  .send-item-content {
    line-height: 18px;
    font-size: 12px;
    color: #4a4a4a;
    // overflow: hidden;
    // -webkit-line-clamp: 3;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
  }

  // .txt-line-3 {
  //   overflow: hidden;
  //   -webkit-line-clamp: 3;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  // }

  .task-info {
    // padding-top: 12px;
    // padding-bottom: 8px;
    .task-title {
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      padding-bottom: 4px;
    }

    &-item {
      line-height: 16px;
      font-size: 12px;
      color: #999999;
    }

    .task-info-item {
      // font-size: 12px;
      font-weight: 300;
      color: #999;
    }
  }

  .task-time-box {
    margin-bottom: 12px;
  }

  .media-box {
    display: flex;
    // flex-wrap: wrap;
    // max-height: ;
    // margin-top: 12px;
    .media-item {
      width: 48px;
      height: 48px;
      border-radius: 5px;
      margin-right: 4px;
      overflow: hidden;
      position: relative;
    }

    .cover-img {
      width: 48px;
      height: 48px;
      border: 1px solid #f5f5f5;
    }

    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      z-index: 5;
    }

    .over-box {
      font-size: 14px;
      color: #4a4a4a;
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>
